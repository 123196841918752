export const imageUrl = (url, resize) => {
  if (!url) {
    return '';
  }
  if (url) {
    if (!url.startsWith('http')) {
      let baseURL = '';
      if (process.env.NODE_ENV === 'development') {
        baseURL = 'https://static.dev-latteprint.com/';
      } else baseURL = `https://static.latteprint.com/`;
      if (process.env.NODE_ENV === 'development') {
        baseURL = 'https://static.dev-latteprint.com/';
      }
      if (resize === true) {
        baseURL = `${baseURL}img/1001/0/resize/`;
      }
      const path = baseURL + url;
      return path;
    }
    return url;
  }
};
