<template>
  <div class="product-page--create">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Catalogs" route="website.catalog" />
        <div class="page-header">
          <h2 class="page-title">{{ item.name }}</h2>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!isLoading">
      <v-col cols="12" sm="6" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <h4 for="">Images</h4>
          <carousel
            class=""
            v-bind="options"
            :navigationEnabled="true"
            :mouse-drag="true"
            :navigation-next-label="nextLabel"
            :navigation-prev-label="prevLabel"
          >
            <template v-if="item && item.mockupGallery.length > 0">
              <slide style="justify-content: center; display: flex" v-for="i in item.mockupGallery" :key="i">
                <v-avatar class="profile" color="grey" size="364" tile>
                  <v-img :src="imageUrl(i)"></v-img>
                </v-avatar>
                <!-- <img :src="imageUrl(i)" /> -->
              </slide>
            </template>
            <template v-else>
              <slide style="justify-content: center; display: flex">
                <v-avatar class="profile" color="grey" size="364" tile>
                  <v-img :lazy-src="imageFake.Emty" :src="imageFake.Emty" alt="" srcset=""> </v-img>
                </v-avatar>
                <!-- <img :src="imageUrl(i)" /> -->
              </slide>
            </template>
          </carousel>
        </div>
        <div class="elevation-form my-3 pa-3">
          <h4 for="">Description</h4>
          <div style="    overflow: auto;" v-html="item.description">></div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex flex-column">
        <div class="elevation-form pa-2">
          <h4 for="">Tags</h4>
          <div>
            <v-chip v-for="i in item.tags" :key="i._id" class="ma-2">
              {{ i.name }}
            </v-chip>
          </div>
        </div>
        <div class="elevation-form my-3 pa-3">
          <h4 for="">Variants</h4>
          <div>Select variants to preview product cost & image</div>
          <div>Sizes</div>
          <div>
            <v-btn class="mr-2" small v-for="i in item.sizes" :key="i.name" outlined>
              {{ i.name }}
            </v-btn>
          </div>
          <div>Colors</div>
          <div>
            <v-btn class="mr-2" small v-for="i in item.colors" :key="i.name" outlined>
              {{ i.name }}
            </v-btn>
          </div>
          <v-divider class="my-3" />
          <v-btn color="primary" @click="addProductToStore"> Import To Store </v-btn>
        </div>
        <div class="elevation-form pa-3 my-3">
          <h4 for="">Shipping information</h4>
          <div>
            <v-select
              v-model="shipCode"
              :items="[
                { name: ' United States ', value: 'US' },
                { name: 'Rest Of Wolrd', value: 'ROW' },
              ]"
              item-text="name"
              item-value="value"
            ></v-select>
          </div>
          <div>First item: {{ item.variants[0].shippingCode[this.shipCode].shipfirstItem }} $</div>
          <!-- <div>Additional item: {{ item.variants[0].shippingCode[this.shipCode].shipAdditionalItem }} $</div> -->
        </div>
        <div class="elevation-form pa-3">
          <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
            <v-tab class="text-lowercase-custom--tab" :key="0">About this product</v-tab>
            <v-tab class="text-lowercase-custom--tab" :key="1"> Refund & return</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" style="    overflow: auto;">
            <v-tab-item :key="0">
              <div v-html="item.aboutDescription"></div>
            </v-tab-item>
            <v-tab-item :key="1">
              <div v-html="item.returnDescription"></div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';

import { dropshipService } from '@/apis/dropship';
import { Carousel, Slide } from 'vue-carousel';
import { imageUrl } from '@/helpers/imagePrint';

import imageHelpers from '@/helpers/image';
import { mapGetters } from 'vuex';
import { imageFake } from '@/const/image';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      shipCode: 'US',
      tab: 0,
      isLoading: false,
      imageFake,
      idItem: '',
      name: '',
      item: {},
      imageUrl,
      nextLabel: '<i style="margin-left: -42px;font-size: 36px" class="fas fa-chevron-right pr-1"></i>',
      prevLabel: '<i style="margin-right: -42px;font-size: 36px" class="fas fa-chevron-left ml-1"></i>',
      options: {
        loop: true,
        perPage: 1,
        paginationEnabled: false,
      },
    };
  },
  mounted() {},
  async created() {
    this.loading = true;
    await this.getData();
    this.viewDetail();
    this.loading = false;
  },
  methods: {
    async addProductToStore() {
      try {
        let res = await dropshipService.createProductImportList([{ catalogId: this.idItem }]);
        this.$router.push({ name: 'website.import-list' });
        await dropshipService.increaseImport(`ids=${this.idItem}`);
      } catch (error) {
        console.log('error', error);
        this.isSubmit = false;
      }
    },
    converShipping(array) {
      let keyShipping = {};
      array.forEach(i => {
        keyShipping[i.countryCode] = i;
      });
      if (!keyShipping.US) {
        keyShipping.US = {
          countryCode: 'US',
          countryName: 'United State',
          method: 'standard',
          shipAdditionalItem: 0,
          shipfirstItem: 0,
        };
      }
      if (!keyShipping.ROW) {
        keyShipping.ROW = {
          countryCode: 'ROW',
          countryName: 'Rest Of World',
          method: 'standard',
          shipAdditionalItem: 3.59,
          shipfirstItem: 6.49,
        };
      }
      return keyShipping;
    },
    async viewDetail() {
      try {
        let data = await dropshipService.increaseView(`ids=${this.idItem}`);
      } catch (error) {}
    },
    async getData() {
      try {
        this.isLoading = true;
        this.idItem = this.$route.params.id;
        let data = await dropshipService.getCatalogById(this.idItem);
        let convertItem = data.data;
        this.item = {
          tabActive: 1,
          ...convertItem,
          isLoading: false,
          priceCost: convertItem.variants.map(item => (item.cost ? parseFloat(item.cost) : 0)).sort(),
          variants: convertItem.variants.map(v => {
            return {
              ...v,
              cost: parseFloat(v.cost || 0),
              shippingCode: JSON.parse(JSON.stringify(this.converShipping(v.shippingInfos))),
              price: (
                parseFloat(v.cost || 0) +
                // this.payment.processingFee +
                // this.payment.paymentFee +
                JSON.parse(JSON.stringify(this.converShipping(v.shippingInfos)))[`US`].shipfirstItem +
                20
              ).toFixed(2),
              compareAtPrice: (
                parseFloat(v.cost || 0) +
                // this.payment.processingFee +
                // this.payment.paymentFee +
                JSON.parse(JSON.stringify(this.converShipping(v.shippingInfos)))[`US`].shipfirstItem +
                40
              ).toFixed(2),
            };
          }),
        };
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss">
.product-page--create {
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
.text-lowercase-custom--tab {
  text-transform: capitalize !important;
}
</style>
