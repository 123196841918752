import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = ``;
let domain = ``;
if (process.env.NODE_ENV === 'development') {
  domain = `https://fulfill.latteprint.com/api/`;
  // domain = `https://admin.latteprint.net/api/`;
  path = `${domain}public/catalogs/`;
} else {
  domain = `https://fulfill.latteprint.com/api/`;
  path = `${domain}public/catalogs/`;
}

function listCatalog(params) {
  return storeRequest.get(path + 'fulfillment/products', { params: params });
}
function listTags(params) {
  return storeRequest.get(path + 'fulfillment/categories', { params: params });
}
function getCatalogById(id) {
  return storeRequest.get(path + id);
}

function create(discount) {
  return storeRequest.post(path, discount);
}

function update(discount) {
  return storeRequest.put(path, discount);
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}

function deleteByIds(ids) {
  return storeRequest.delete(path + '?ids=' + ids);
}

function checkExistsAutoActive() {
  return storeRequest.get(path, { params: { discount_status: 'active', discount_type: 'automatic' } });
}

const letPathImport = '/products/import-list';
function getProductImportList(data) {
  return storeRequest.get(letPathImport + '/search');
}
function createProductImportList(data) {
  return storeRequest.post(letPathImport + '/add-catalog', data);
}
function removeProductImportList(data) {
  return storeRequest.post(letPathImport + '/remove-all?' + data);
}
function increaseImport(data) {
  return storeRequest.get(`${path}/increase/inc-import?` + data);
}
function increaseView(data) {
  return storeRequest.get(`${path}/increase/inc-view?` + data);
}
export const dropshipService = {
  increaseImport,
  increaseView,
  listCatalog,
  removeProductImportList,
  createProductImportList,
  getProductImportList,
  getCatalogById,
  listTags,
  create,
  update,
  deleteById,
  checkExistsAutoActive,
  deleteByIds,
};
